import React, { Fragment } from 'react';
import { AppActionCreators, ApplicationState, AppState, CaseSettingsState } from '../../store';
import { ApplicationContext, AppContext } from '../../context/Contexts';
import { ProviderContext, withSnackbar } from 'notistack';
import { RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import { ITranslatorService } from '../../services/Interfaces/ITranslatorService';
import { IAppUserService } from '../../services/Interfaces/IAppUserService';
import { ICaseService } from '../../services/Interfaces/ICaseService';
import { CaseComment, NotifySupportRequest } from '../../interfaces/Case';
import { Button, List, ListItem, Divider, Menu, MenuItem } from '@material-ui/core';
import moment from 'moment';
import { ScaleLoader } from 'react-spinners';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CommentTypeCode, NotifySupportTemplateEnum, RoleClaimsEnum } from '../../helpers/Constants';
import { isNullOrUndefined } from 'util';
import _ from 'lodash';

interface ICaseSupportCommentsState {
  caseSupportComments: CaseComment[];
  commentText: string | null;
  isLoading: boolean;
  selectedCommentType: string;
  openMenuItem: any | null;
  selectedCaseCommentId: number | null;
  editComment: boolean;
  editTextComment: string | null;
}

type ICaseSupportCommentsProps = {
  caseSettingsState: CaseSettingsState;
  appState: AppState;
} & typeof AppActionCreators &
  ProviderContext &
  RouteComponentProps<{ id: string }>;

class CaseDetailsCaseSupportCommentsTab extends React.PureComponent<ICaseSupportCommentsProps, ICaseSupportCommentsState> {
  private translatorService!: ITranslatorService;
  private appUserService!: IAppUserService;
  private caseService!: ICaseService;

  static contextType = ApplicationContext;

  public hasRight = (right: string): boolean => {
    return this.props.appState!.user!.profile.role.includes(right);
  };

  state = {
    caseSupportComments: [],
    commentText: '',
    isLoading: false,
    selectedCommentType: !this.hasRight(RoleClaimsEnum.AdminSupport) ? CommentTypeCode.SUPPORT_REQUEST : CommentTypeCode.SUPPORT_RESPONSE,
    openMenuItem: null,
    selectedCaseCommentId: null,
    editComment: false,
    editTextComment: null
  } as ICaseSupportCommentsState;

  public componentDidMount() {
    const caseId = Number.parseInt(this.props.match.params.id);
    if (Number.isNaN(caseId)) {
      return;
    }

    this.setState(
      {
        isLoading: true
      },
      async () => {
        await this.loadCaseSupportComments();
      }
    );
  }

  loadCaseSupportComments = async () => {
    const caseId = Number.parseInt(this.props.match.params.id);
    if (Number.isNaN(caseId)) {
      return;
    }
    const caseComments = await this.caseService.GetCaseCommentsByTab(caseId, true);

    if (caseComments.length === 0) {
      this.setState({
        isLoading: false
      });

      return;
    }

    const users = await this.appUserService.GetUsersInfo(
      Array.from(new Set(caseComments.map((item) => item.userId.toString())))
    );

    for (const cc of caseComments) {
      const usr = users.find((item) => item.id === cc.userId.toString());
      cc.user = isNullOrUndefined(usr) ? null : usr;
    }

    this.setState({
      isLoading: false,
      caseSupportComments: caseComments.sort(function (a: any, b: any) {
        return b.date - a.date;
      })
    });
  };

  mailHandleClick = (e: any, id: number) => {
    const index = this.state.caseSupportComments.findIndex((item) => item.id === id);
    this.setState({
      selectedCaseCommentId: id,
      openMenuItem: e.currentTarget,
      editTextComment: this.state.caseSupportComments[index].text,
      editComment: false
    });
  };

  handleDeleteComment = async () => {
    try {
      const comments = [...this.state.caseSupportComments];

      await this.caseService.RemoveCaseComment(this.state.selectedCaseCommentId!);

      const index = comments.findIndex((item) => item.id === this.state.selectedCaseCommentId);
      comments.splice(index, 1);

      this.setState({ caseSupportComments: comments, openMenuItem: false });
    } catch (err) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  };

  handleEditComment = () => {
    this.setState({ openMenuItem: false, editComment: true });
  };

  editCaseComment = async () => {
    try {
      const comments = [...this.state.caseSupportComments];
      const index = comments.findIndex((item) => item.id === this.state.selectedCaseCommentId);
      comments[index].text = this.state.editTextComment === null ? '' : this.state.editTextComment;

      const adminSupport = this.hasRight(RoleClaimsEnum.AdminSupport);
      const userSupportComment = this.state.caseSupportComments.find((item) => item.commentTypeCode === CommentTypeCode.SUPPORT_REQUEST && item.userId !== this.props.appState.appUser!.id);
      
      if (adminSupport && !userSupportComment) {
        this.props.enqueueSnackbar(this.translatorService.Tranlate('NO_USER_FOUNDED', 'Nu s-a gasit utilizatorul!'), {
          variant: 'error'
        });
        return;
      }

      const request =  {
        caseId: this.props.caseSettingsState.case!.id,
        toUserId: adminSupport ? userSupportComment?.userId : null,
        template: this.hasRight(RoleClaimsEnum.AdminSupport) ? NotifySupportTemplateEnum.CASE_SUPPORT_RESPONSE : NotifySupportTemplateEnum.CASE_NOTIFY_SUPPORT,
        caseComment: comments[index]
      } as NotifySupportRequest;
      
      await this.caseService.SendNotifySupport(request);

      this.setState({ editComment: false, openMenuItem: false, caseSupportComments: comments });
    } catch (err) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  };

  displayText = (text: string) => {
    const lines = text.split('\n');

    return lines.map((item: string, index: number) => (
      <p key={index} className="font-size-xs mb-0">
        {item}
      </p>
    ));
  };

  renderCaseComments = () => {
    const caseSupportComments = [] as any[];
    if (this.state.caseSupportComments.length !== 0) {
      this.state.caseSupportComments.forEach((comment: CaseComment, index: number) => {
        caseSupportComments.push(
          <div key={index}>
            {index === 0 ? null : <Divider />}
            <div>
              <ListItem className="bg-secondary text-uppercase px-4 py-2 font-weight-bold font-size-xs">
                {moment.utc(comment.date).local().format('L')}
              </ListItem>
              <Divider />
            </div>
            <ListItem>
              <div className="rounded-0 p-2 w-100">
                <div>
                  <div className="d-flex justify-content-between">
                    <div className="w-25 pl-3">
                      <div className="d-flex-row my-1">
                        <span className="text-black-50 opacity-5">
                          {moment.utc(comment.date).local().format('LT')}
                        </span>
                        <div className="font-weight-bold">
                          {comment.user!.firstName +
                            ' ' +
                            comment.user!.lastName +
                            ' (' +
                            comment.user!.organization!.hoName +
                            ')'}
                        </div>
                      </div>
                    </div>
                    <div className="w-50">
                      {this.state.selectedCaseCommentId !== comment.id ||
                      this.state.editComment === false ? (
                        this.displayText(comment.text)
                      ) : (
                        <ValidatorForm onSubmit={this.editCaseComment}>
                          <div>
                            <TextValidator
                              name="commentText"
                              variant="outlined"
                              margin="dense"
                              fullWidth
                              multiline
                              rowsMax={3}
                              value={this.state.editTextComment}
                              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                this.setState({ editTextComment: e.currentTarget.value });
                              }}
                              validators={['required']}
                              errorMessages={[]}
                            />
                          </div>
                          <div className="text-right">
                            <Button
                              className="mr-2"
                              size="small"
                              variant="outlined"
                              color="primary"
                              onClick={(e) => {
                                this.setState({ editComment: false, openMenuItem: false });
                              }}
                            >
                              {this.translatorService.Tranlate('CANCEL', 'Anuleaza')}
                            </Button>
                            <Button size="small" variant="contained" color="primary" type="submit">
                              {this.translatorService.Tranlate('CASE_COMMENT_SEND', 'Trimite')}
                            </Button>
                          </div>
                        </ValidatorForm>
                      )}
                    </div>

                    <div className="d-flex w-25 justify-content-end">
                      <div className="d-flex flex-column">
                        <Button
                          aria-controls="simple-menu"
                          size="small"
                          variant="outlined"
                          color="primary"
                          hidden={comment.userId !== this.props.appState.appUser!.id}
                          className=" px-2 py-0"
                          aria-haspopup="true"
                          onClick={(e) => this.mailHandleClick(e, comment.id)}
                        >
                          <FontAwesomeIcon icon={['fas', 'ellipsis-h']} className="font-size-lg" />
                        </Button>
                        <Menu
                          id="simple-menu"
                          anchorEl={this.state.openMenuItem}
                          keepMounted
                          open={Boolean(this.state.openMenuItem)}
                          onClose={(e) => {
                            this.setState({ openMenuItem: false });
                          }}
                        >
                          <MenuItem onClick={this.handleEditComment}>
                            <ListItem button className="rounded-sm">
                              <div className="nav-link-icon opacity-6">
                                <FontAwesomeIcon icon={['fas', 'pen']} />
                              </div>
                              <span>{this.translatorService.Tranlate('EDIT', 'Editeaza')}</span>
                            </ListItem>
                          </MenuItem>
                          <MenuItem onClick={this.handleDeleteComment}>
                            <ListItem button className="rounded-sm">
                              <div className="nav-link-icon opacity-6">
                                <FontAwesomeIcon icon={['fas', 'trash']} />
                              </div>
                              <span>{this.translatorService.Tranlate('DELETE', 'Sterge')}</span>
                            </ListItem>
                          </MenuItem>
                        </Menu>

                        <Button
                          disableRipple
                          size="small"
                          variant="outlined"
                          color="primary"
                          className=" px-2 py-0 mt-1"
                          style={{ backgroundColor: 'transparent' }}
                        >
                          {this.translatorService.Tranlate(
                            'CASE_SUPPORT_COMMENT_TYPE_' + comment.commentTypeCode.toUpperCase(),
                            comment.commentTypeCode
                          )}
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ListItem>
          </div>
        );
      });
    }
    return caseSupportComments;
  };

  addCaseComment = async () => {
    if (this.state.commentText !== null) {
      try {
        const adminSupport = this.hasRight(RoleClaimsEnum.AdminSupport);
        const userSupportComment = this.state.caseSupportComments.find((item) => item.commentTypeCode === CommentTypeCode.SUPPORT_REQUEST && item.userId !== this.props.appState.appUser!.id);
        
        if (adminSupport && !userSupportComment) {
          this.props.enqueueSnackbar(this.translatorService.Tranlate('NO_USER_FOUNDED', 'Nu s-a gasit utilizatorul!'), {
            variant: 'error'
          });
          return;
        }

        const request =  {
          caseId: this.props.caseSettingsState.case!.id,
          toUserId: adminSupport ? userSupportComment?.userId : null,
          template: adminSupport ? NotifySupportTemplateEnum.CASE_SUPPORT_RESPONSE : NotifySupportTemplateEnum.CASE_NOTIFY_SUPPORT,
          caseComment: {
            id: 0,
            caseId: this.props.caseSettingsState.case!.id,
            text: this.state.commentText,
            commentTypeCode: this.state.selectedCommentType,
            isFromSupportTab: true
          } as CaseComment
        } as NotifySupportRequest;
        const caseComment = await this.caseService.SendNotifySupport(request);

        caseComment.user = this.props.appState.appUser;
        const caseComments = [caseComment, ...this.state.caseSupportComments];

        this.setState({
          commentText: '',
          caseSupportComments: caseComments
        });

        this.props.LoadComments();
      } catch (error) {
        this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
          variant: 'error'
        });
      }
    }
  };


  public render() {
    this.translatorService = (this.context as AppContext).translatorService;
    this.caseService = (this.context as AppContext).caseService;
    this.appUserService = (this.context as AppContext).appUserService;

    return (
      <Fragment>
        <div className="d-flex flex-row text-center flex-wrap justify-content-center">
          <ScaleLoader color={'var(--primary)'} loading={this.state.isLoading} />
        </div>
        {!this.state.isLoading ? (
          <div className="app-inner-content-layout ">
            <div className="w-100 bg-white p-0">
              <div className="app-content--inner__footer bg-white m-3">
                <ValidatorForm onSubmit={this.addCaseComment}>
                  <div>
                    <TextValidator
                      name="commentText"
                      variant="outlined"
                      margin="dense"
                      fullWidth
                      multiline
                      rowsMax={3}
                      placeholder={this.translatorService.Tranlate(
                        'CASE_COMMENT_PLACEHOLDER_SEND',
                        'Write your message...'
                      )}
                      value={this.state.commentText}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        this.setState({ commentText: e.currentTarget.value });
                      }}
                      validators={['required']}
                      errorMessages={[]}
                    />
                  </div>
                  <div className="d-flex justify-content-end mt-2">
                    <Button  size="small" variant="contained" color="primary" type="submit">
                      {this.translatorService.Tranlate('CASE_COMMENT_SEND', 'Trimite')}
                    </Button>
                  </div>
                </ValidatorForm>
              </div>
              <Divider />
              <List className="pt-0">{this.renderCaseComments()}</List>
              <Divider />
              <div className="app-content--inner__footer bg-white m-3">
              </div>
            </div>
          </div>
        ) : null}
      </Fragment>
    );
  }
}

export default connect(
  (state: ApplicationState) => {
    return { caseSettingsState: state.caseSettings, appState: state.app };
  },
  AppActionCreators
)(withSnackbar(CaseDetailsCaseSupportCommentsTab as any));
